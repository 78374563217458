import logo from './images/logo.png';
import './App.css';
import Tilt from './TiltPhaseSix'
import FadeIn from "react-fade-in";

const options = {
  max: 10,
  perspective: 1000,
  scale: 1,
}

function App() {
  return (
    <div className="App">
      <div className="content">
      
        <div className="left">
          <FadeIn delay={300} transitionDuration={1000}>
            <div className="img">
                <Tilt
                  options={{}}
                  style={{
                    height: 300,
                    width: 300,
                  }}>
                    <Tilt options={options}>
                      <img src={logo} alt="" />
                    </Tilt>
                </Tilt>
            </div>
          </FadeIn>
        </div>
        
        <div className="right">
          <FadeIn delay={800} transitionDuration={1000}>
            <div className="innerContent">

              <p>
                <b>We plan, create and execute products with you in mind.</b> We build custom products focused on ease of use, designed to keep your life simple and frustration free.
                <br/><br/>
                Founded by a robust team of Engineers, Developers and Business Specialists with a shared interest and experience in the medical and aesthetics industry.
                <br/><br/>
                We are an Australian based company, <a href="mailto:contact@nkdtech.com.au;?subject=From Website">get in touch</a> anytime.
              </p>
            </div>
          </FadeIn>
        </div>
        
      </div>
    </div>
  );
}

export default App;
